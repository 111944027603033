import React, {useState, useEffect} from 'react'
import {useAbilityContext} from '../useAbilityContext'
import {BackButton, Page, Toolbar, Toast, BottomToolbar, ProgressCircular} from 'react-onsenui'
import {ActionSheet, ActionSheetButton, Icon, PullHook, SpeedDial, SpeedDialItem, Fab /*Checkbox*/} from 'react-onsenui'
import {Button, Box, Text} from 'grommet'
import {Table, TableHeader, TableRow, TableBody, TableCell} from 'grommet'
import {FormNext} from 'grommet-icons'
import moment from 'moment'
import {TimePicker, StatusIcons} from '../components'
import {store, fetch, Can, a, an, This, Login, Logout, Routes, uid} from '../psrs'

import { SwipeableList, SwipeableListItem, ActionAnimations } from '@sandstreamdev/react-swipeable-list';
//import '@sandstreamdev/react-swipeable-list/dist/styles.css';
import PullToRefresh from 'react-simple-pull-to-refresh';

import '../styles/approvals.css'

import _ from 'lodash'

// <Icon icon="md-assignment-check" />
//
// <Icon icon="md-hospital-alt" />
//

const DEBUG = false

export const Shifts = (props) => {
  const {navigator, route} = props
  const {view} = route
  const currentView = store.get('View')
  // console.log("route.data", route.data)
  const ability = useAbilityContext()
  const Employee = route.data && route.data.employee ? route.data.employee : store.get('CurrentUser')

  // if no employee -- go login then

  //if (Employee.number !== store.get("CurrentUser").number) console.log('Not the same......')

  //const viewData = route.data && route.data.shifts ? route.data.shifts : store.get('ViewData')
  
  const Shifts = [] //viewData ? viewData : []
  const _selected = new Set(store.get('ShiftsSelected'))
  const currentPayPeriod = store.get('PayPeriodToday')
  const [payPeriod, setPayPeriod] = useState(store.get('PayPeriod'))
  
  // handle missing local store.. not sure where it gets wiped
  if (payPeriod === 'undefined') {
    setPayPeriod(store.get('PayPeriodToday'))
    store.set('PayPeriod',store.get('PayPeriodToday'))
  }
  
  //const [payPeriodIndex, setPayPeriodIndex] = useState(payPeriod.i)
  const [payPeriodIndex, setPayPeriodIndex] = useState(store.get('PayPeriod').i)
  const [shifts, setShifts] = useState(_.orderBy(Shifts, 'start', 'asc'))
  const [toast, setToast] = useState({open: false, text: ''})
  // const [overlayed, setOverlayed] = useState(false)
  const [actions, setActions] = useState({
    selected: new Set(),
    open: false,
  })

  const [pullHookState, setPullHookState] = useState('')
  const [isMounted, setIsMounted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  
  const _picker = {
    open: false,
    field: null,
    shift: null,
  }
  const [picker, setPicker] = useState(_picker)

  const pushPage = (S) => {
    store.set('Shift', S)
    navigator.pushPage({
      view: 'Shift',
      data: {shift: S},
      id: S.id,
    })
  }

  const fetchRecords = async (signal) => {
    setIsLoading(true)
    let records = []
    if (isMounted) setShifts([])

    if (currentView === 'MyShifts') {
      records = await fetch(`/api/employee/${Employee.number}/shifts/${payPeriod.id}`, { signal: signal })
    }
    if (currentView === 'Approvals') {
      records = await fetch(`/api/approvals/shift`, { signal: signal })
    }
    if (currentView === "EmpShifts") {
      records = await fetch(`/api/employee/${Employee.number}/shifts/${payPeriod.id}`, { signal: signal })
    }
    // console.log(
    //   currentView,
    //   records.map((r) => `Needs Approved: ${r.needsApproved}`)
    // );
    const ordered = _.orderBy(records, 'start', 'asc')
    store.set('Shifts', ordered)

    if (isMounted) setShifts(ordered)
    setIsLoading(false)
    return ordered
  
  }

  const pullRefresh = async () => {

    fetchRecords()
    .then ((records) => {
        store.set('Shifts', records)
        setShifts(records)
      
    })

  }
  
  const handleNewShift = async (e) => {
    const currentView = store.get("View");
    const view = 'NewShift' //e.target.getAttribute("view");
    
    let canCreateShift = true

    if (currentView === view && view !== "Login") {
      canCreateShift = false
    }

    DEBUG && console.log("handleAction navigator", navigator);
    const route = { view };
    const exclude = ['Shift', view]
    const routes = navigator.routes.filter(r => !exclude.includes(r.view));
    routes.push(route);
    store.set('RouteStack', routes)
    DEBUG && console.log("handleAction routes", routes)
  
    if (canCreateShift) {
      navigator.resetPageStack(_.uniqBy(routes, "view"));
    }
  }


  const AllowSubmit = async (ids) => {
    const canSubmitShift = []
    const cannotSubmitShift = []
    await ids.forEach(e => {
      const checkShift = shifts.find( s => s.id === e )
      
      if (checkShift ) {

        // only allow 30 minutes before 
        const checkit =  moment().subtract(30, "minutes")
        const checkend = moment(checkShift.end)
        const canSubmit = checkit > checkend
        
        // check notes required 
        let requiredNotes = true
        let requiredReason = true
        if (checkShift.isOT || checkShift.isSick) {
          if (checkShift.notes === null || checkShift.notes === '') {
            requiredNotes = false
          }
        }

         // check reason required if OT 
        if (checkShift.isOT && checkShift.reasonId === null) {
          requiredReason = false
        }

        if (canSubmit && requiredNotes && requiredReason) {
          canSubmitShift.push(e)
        } else {
          cannotSubmitShift.push(e)
        }

      }
    });


    setToast({open: true, text: `Submitting ${canSubmitShift.length} ignoring ${cannotSubmitShift.length}`})
    setTimeout(() => setToast({...toast, open: false}), 2500)

    return ({allowed: canSubmitShift, notallowed: cannotSubmitShift})

  }

  const updateShifts = async (params) => {
    const ids = Array.from(actions.selected.values())

    let allowedIds = ids
    if (params.submit) {
      const checks = await AllowSubmit(ids)
      allowedIds = checks.allowed
    }
   
    setActions({selected: new Set(), open: false})
    //await fetch(`/api/shifts`, {method: 'PATCH', body: JSON.stringify({params, ids})})
    await fetch(`/api/shifts`, {method: 'PATCH', body: JSON.stringify({params, ids: allowedIds})})
    await pullRefresh()
    return true
  }

  const deleteShifts = async (params) => {
    const ids = Array.from(actions.selected.values())
    setActions({selected: new Set(), open: false})
    await fetch(`/api/shifts`, {method: 'DELETE', body: JSON.stringify({params, ids})})
    await pullRefresh()
    return true
  }

  const updateShift = async (args) => {
    const {field, value, shiftId} = args
    const body = {
      id: shiftId,
    }
    body[field] = value
    await fetch(`/api/shifts/${args.shiftId}`, {method: 'PATCH', body: JSON.stringify(body)})    
    await pullRefresh()
    return true
  }

  const xxsm = {size: 'xxsmall'}

  const closePickerAndSave = (value, field, shiftId) => {
    setPicker({...picker, open: false})
    updateShift({field, value, shiftId})
  }

  const closePicker = () => {
    setPicker({...picker, open: false})
  }

  const toggleActions = () => {
    setActions({...actions, open: !actions.open})
  }

  const openPicker = (shift, field) => {
    setPicker({open: true, field, shift, closePickerAndSave})
  }

  const TimeBox = ({value, field, shift, disabled, checked}) => {
    const P = {
      align: 'start',
      alignContent: 'start',
      textAlign: 'start',
      hoverIndicator: true,
      round: 'small',
      pad: 'small',
      wrap: false,
    }
    const xTB = {
      start: {...P, background: 'green'},
      end: {...P, background: 'yellow'},
      hrs: {...P, background: 'grey'},
      ot: {...P, background: 'red'},
    }
 
    
    const TB = {
      start: {...P, background: '#EFEFF4'},
      end: {...P, background: '#EFEFF4'},
      hrs: {...P, background: '#EFEFF4'},
      ot: {...P, background: '#EFEFF4'},
    }
    let TBP = TB[field]

    if (checked) {
      TBP = {...TBP, background: 'LightSteelBlue' }
    }
    if (disabled) {
      TBP = {...TBP, background: 'lightgrey' }
    }

    if (disabled !== true && field === 'start') {
      TBP.onClick = () => {
        openPicker(shift, field)
      }
    }
    if (disabled !== true && field === 'end') {
      TBP.onClick = () => {
        openPicker(shift, field)
      }
    }
    return (
      <Box {...TBP}>
        <Text size='xsmall' textAlign='center'>
          {value}
        </Text>
      </Box>
    )
  }

  const TOP = {alignSelf: 'stretch', margin: 'none'}
  const BT = {border: 'top'}
  const TD = {...BT, size: 'xsmall', pad: 'xsmall'}

  const renderRow = (S) => {
    const SS = moment(S.start)
    const SE = moment(S.end)
    const DDD = SS.format('ddd')
    const MO = SS.format('MMM')
    const DO = SS.format('Do')
    const ST = SS.format('HH:mm')
    const ET = SE.format('HH:mm')
    const DUR = S.duration ? `${S.duration.toFixed(2)}` : '0.00'
    const OT =  S.durationOt ? `${S.durationOt.toFixed(2)}` : '0.00'
    const shiftDate = SS.format('ddd MM/DD/YY')
    const altShiftDate = SS.format('MM/DD/YY')
   
    
    const Date = () => (
      <div className='DateColumn'>
       
        <div className='Month'>
            <small><strong>{DDD}</strong>{'|'}{altShiftDate}</small>
            
        </div>
        {/* <div className='WeekDay'>{DDD}</div>
        <div className='Month'>{MO}</div>
        <div className='Date'>{DO}</div> */}
      </div>
    )
    const Shift = S


    const justAbility = ability.can('edit:shifts', This('Employee', Employee))
    const justAbilityCannot = ability.cannot('edit:shifts', This('Employee', Employee)) 
    const justSubmit = !S.submit
    const justProcess= !S.process
    const justSick = !S.isSick
    const justPTO = !S.requestedOff

    const cantEdit = ability.cannot('edit:shifts', This('Employee', Employee)) || !!S.submit || !!S.process
    const uncheckable = !!S.isSick || !!S.requestedOff || !!S.submit || !!S.process || !!S.locked
   // const uncheckable = !!S.submit || !!S.process || !!S.locked

    const statusProps = {
      textAlign: 'center',
      // round: "small",
      // pad: "small",
      wrap: false,
      fill: true,
      direction: 'row',
      align: 'center',
    }
    const iconProps = {margin: {right: '6px'}}

    const otStatus = S.isOT && !S.process
    const sickStatus = S.isSick && !S.process
    const ptoStatus = S.requestedOff && !S.process
    const submittedStatus = S.submit && !S.process
    const processedStatus = !!S.process
    const onDemandShift = S.shiftTemplateId !== null
    const locked = S.locked
    const approved = S.approved
    const rejected = S.rejected
    const acting = S.assignmentId
    const showNotes = false
    let disableCheckbox = view === 'Approvals' ? false : uncheckable ? true : cantEdit ? true : false
    disableCheckbox = view === 'EmpShifts' ? false : disableCheckbox

    return (
      <React.Fragment key={`${S.id}Row`}>
        {/* <SwipeableListItem
          swipeLeft={{
            content: 
            <span className="basic-swipeable-list__item-content-right">Un-Submit</span>,
            //actionAnimation: 'REMOVE',
            action: () => {
              //updateShifts({submit: false})
              updateShift({field: 'submit', value: false, shiftId: S.id})
              console.info('swipe action triggered')
            }
          }}
          swipeRight={{
            content: <span className="basic-swipeable-list__item-content-left">Submit</span>,
            action: () => {
              //updateShifts({submit: true})
              updateShift({field: 'submit', value: true, shiftId: S.id})
              console.info('swipe action triggered')
            }
          }}
          onSwipeProgress={progress => console.info(`Swipe progress: ${progress}%`)}
        > */}

          <TableRow key={`shiftRow${S.id}`} width='100vw' className='ShiftTrData'>
            <TableCell {...TD}>
              <Box
                  fill
                  align="stretch"
                  direction="row"
                  pad="small"
                  round='small'
                  background={actions.selected.has(S.id) ? "LightSteelBlue" : disableCheckbox? "lightgrey" : "#EFEFF4"} 
                  hoverIndicator = { {color: disableCheckbox ? "lightgrey" : "LightSteelBlue" } }
                  onClick = {() =>{
                      if (disableCheckbox) return 

                      const checked = actions.selected.has(S.id)
                      if (!checked) actions.selected.add(S.id)
                      if (checked) actions.selected.delete(S.id)
                      setActions({...actions})
                    }}
                >
                  <Date />
              </Box>      
            </TableCell>
            <TableCell {...TD}>
              <small><TimeBox disabled={cantEdit || disableCheckbox} value={ST} field='start' shift={S} checked={actions.selected.has(S.id)} /></small>
            </TableCell>
            <TableCell {...TD}>
              <small><TimeBox disabled={cantEdit || disableCheckbox} value={ET} field='end' shift={S} checked={actions.selected.has(S.id)} /></small>
            </TableCell>
            <TableCell {...TD}>
              <small><TimeBox disabled={cantEdit || disableCheckbox} value={DUR} field='hrs' shift={S}  checked={actions.selected.has(S.id)} /></small>
            </TableCell>
            <TableCell style={{width: '45px'}} className='otStatus'>
              {!!sickStatus && (
                <Box {...statusProps}>
                  <Box {...iconProps}>
                    <Icon icon='md-plaster' style={{color: '#98312C'}} size={18} />
                  </Box>
                  <Box>
                    <span className='status'><small>SICK</small></span>
                  </Box>
                </Box>
              )}
              {!!ptoStatus && (
                <Box {...statusProps}>
                  <Box {...iconProps}>
                    <Icon icon='md-calendar' style={{color: '#E47641'}} size={18} />
                  </Box>
                  <Box>
                    <span className='status'><small>LEAVE</small></span>
                  </Box>
                </Box>
              )}
              {!!submittedStatus && (
                <Box {...statusProps}>
                  <Box {...iconProps}>
                    <Icon icon='md-assignment-check' style={{color: '#3482A9'}} size={18} />
                  </Box>
                  <Box>
                    <span className='status'><small>SUBMIT'D</small></span>
                  </Box>
                </Box>
              )}
              {!!processedStatus && (
                <Box {...statusProps}>
                  <Box {...iconProps}>
                    <Icon icon='md-money-box' style={{color: 'green'}} size={18} />
                  </Box>
                  <Box>
                    <span className='status'><small>PROCESS'D</small></span>
                  </Box>
                </Box>
              )}
              {!!otStatus && (
                <Box {...statusProps}>
                  <Box {...iconProps}>
                    <Icon icon='md-time' style={{color: 'black'}} size={18} />
                  </Box>
                  <Box>
                    <span className='status'><small>OT</small></span>
                  </Box>
                </Box>
              )}
              {!!onDemandShift && (
                <Box {...statusProps}>
                  <Box {...iconProps}>
                    <Icon icon='md-alarm-plus' style={{color: '#CD853F'}} size={18} />
                  </Box>
                  <Box>
                    <span className='status'><small>New</small></span>
                  </Box>
                </Box>
              )}
              {!!acting && (
                <Box {...statusProps}>
                  <Box {...iconProps}>
                    <Icon icon='md-shield-security' style={{color: '#2E8B57'}} size={18} />
                  </Box>
                  <Box>
                    <span className='status'><small>ACTING</small></span>
                  </Box>
                </Box>
              )}
              {!!approved && (
                <Box {...statusProps}>
                  <Box {...iconProps}>
                    <Icon icon='md-thumb-up' style={{color: '#70BCFE'}} size={18} />
                  </Box>
                  <Box>
                    <span className='status'><small>APPRV'D</small></span>
                  </Box>
                </Box>
              )}
               {!!rejected && (
                <Box {...statusProps}>
                  <Box {...iconProps}>
                    <Icon icon='md-thumb-down' style={{color: 'red'}} size={18} />
                  </Box>
                  <Box>
                    <span className='status'><small>REJECT'D</small></span>
                  </Box>
                </Box>
              )}
              {!!locked && (
                <Box {...statusProps}>
                  <Box {...iconProps}>
                    <Icon icon='md-lock' size={18} />
                  </Box>
                  <Box>
                    <span className='status'><small>LOCK'D</small></span>
                  </Box>
                </Box>
              )}
              {/* {!uncheckable && <TimeBox disabled={cantEdit} value={OT} field='ot' shift={S} />} */}
            </TableCell>
            <TableCell {...TD}>
              <Button size='small' onClick={() => pushPage(S)} icon={<FormNext />} />
            </TableCell>
          </TableRow>
       
        {/*<TableRow key={`shiftRow${S.id}Status`} widith='100vw' className='ShiftNotes'>
          <TableCell scop="row" colSpan={6}>
              <StatusIcons shift={S} />
          </TableCell>
        </TableRow> */}
        
        {showNotes && (
          <TableRow key={`shiftRow${S.id}Notes`} width='100vw' className='ShiftTrNotes'>
            <TableCell scope="row" colSpan={6}>
              <Box
                align="stretch"
                alignContent="stretch"
                fill="horizontal"
                flex={true}
                direction="row"
                pad='medium' 
                background='white' 
                margin='medium'
              >
                <Text {...xxsm}> {S.notes} </Text>
              </Box>
            </TableCell>
          </TableRow>
          
        )}
        {/* </SwipeableListItem> */}
      </React.Fragment>
    )
  }


  const renderBack = navigator.routes.length > 2

  const shiftName = Employee && Employee.fullName ? Employee.fullName : null

  if (!shiftName) {
    //Logout()
    const routeStack = [Routes.login]
   // store.set('RouteStack', routeStack)
    //window.location.reload(false)
      //store.clearAll()

      //alert('not shiftName logout')
      DEBUG && console.log('not shift name logout')
      store.set('loginConfirmed', false)
      navigator.resetPage({view: 'Login', title: 'Logout'})
    
      DEBUG && console.log('yyy')
    
  }

  const count = actions.selected.size
  const viewTitle = view === 'Approvals' ? 'Approvals' : `${shiftName} Shifts`
  const buttonTitle = view === 'Approvals' ? `Approve / Reject ${count} Shifts` : `Edit ${count} Selected Shifts`
  const buttonPlaceholder = view === 'Approvals' ? 'Select Shifts to Approve / Reject' : 'Select Shifts to Edit'

  const renderToolbar = () => (
    <Toolbar>
      {!!renderBack && (
        <div className='left'>
          <BackButton>Back</BackButton>
        </div>
      )}
      <div className='center'> {viewTitle} </div>
    </Toolbar>
  )

  const canEdit = ability.can('edit', an('Employee', Employee))
  const canApprove = ability.can('approve', an('Employee', Employee))
  const canProcess = ability.can('process', an('Employee', Employee))
  //const canProcess = ability.can('process')

  const renderShiftActionButton = () => {
    const disabled = count === 0
    return ( 
      view !== 'EmpShifts' ?
      <Can I='edit:shifts' an={Employee}>
        <BottomToolbar>
          <Box justify='center' align='center' fill direction='row' pad='none'>
            <Button disabled={disabled} onClick={toggleActions} label={disabled ? buttonPlaceholder : buttonTitle} primary fill />
          </Box>
        </BottomToolbar>
      </Can>
      :
      <BottomToolbar>
        <Box justify='center' align='center' fill direction='row' pad='none'>
          <Button disabled={disabled} onClick={toggleActions} label={disabled ? buttonPlaceholder : buttonTitle} primary fill />
        </Box>
      </BottomToolbar>
    )
  }

  
  const prev = async () => {
    const newPPi = payPeriodIndex - 1
    setPayPeriodIndex(newPPi)
    DEBUG && console.log('PREV')
  }

  const next = async () => {
    const newPPi = payPeriodIndex + 1
    setPayPeriodIndex(newPPi)
    DEBUG && console.log('NEXT')
  }

  const setCurrentPayPeriod = async () => {
    setPayPeriodIndex(currentPayPeriod.i)
  }

  const fetchPayPeriod = async (mounted) => {
    DEBUG && console.log(`payPeriod.i: ${payPeriod.i}, payPeriodIndex: ${payPeriodIndex} current? ${payPeriodIndex === payPeriod.i}`)
    const pp = await fetch(`/api/pay_period/${payPeriodIndex}?employeeNumber=${Employee.number}`)

    if (pp && pp.error && pp.error === 'NOT AUTHENTICATED') Logout()

   
    //if (mounted) {
    //  store.set('PayPeriod', pp.payPeriod)
    //  if (isMounted)  setPayPeriod(pp.payPeriod) //<-- this is generating error... 
    //  setShifts(pp.shifts)
    //}
    
    DEBUG && console.log(pp)
    return pp
  }
  const format = 'MMM Do'

  if (payPeriod === 'undefined') Logout()

  const ppStart = moment(payPeriod.start).format(format)
  const ppEnd = moment(payPeriod.end).format(format)
  const ppTitle = `${ppStart} - ${ppEnd}`
  const title = payPeriod.i === currentPayPeriod.i ? <strong style={{color: 'dodgerblue'}}>{ppTitle}</strong>: ppTitle


  /* useEffect(() => {
    const controller = new AbortController()

    let isMounted = true
    if (isMounted) {
      store.set('Shifts', shifts)
    }
    
    return() => { 
      //store.remove("Shifts")
      isMounted = false
      controller.abort();
      DEBUG && console.log('useEffect shifts')

    }


  }, [shifts])
 */

  useEffect(() => {
    //const controller = new AbortController()

    if (!isMounted) setIsMounted(true)

    return () => {
      DEBUG && console.log('useEffect - fetchRecords')
      setIsMounted(false)
      //controller.abort() 
    } 

  }, [shifts])

  useEffect(() => {
    //const controller = new AbortController()
    //const signal = controller.signal

    let mounted = true
    if (mounted) {
      fetchRecords()
      .then((response) => {
      //  // handle other stuff
      //  console.log('....other....')
      if (mounted) {
        setShifts(response)
        setIsMounted(true)
      }
      })
    } else {
      setIsMounted(true)
      mounted = true
      fetchRecords()
      .then((response) => {
        //  // handle other stuff
        if (mounted) {
          setShifts(response)
          setIsMounted(true)
        }
      })
    } 

  return () => {
      DEBUG && console.log('useEffect - fetchRecords')
      mounted = false
      setIsMounted(false)
      //controller.abort() 
    } 
  }, [])

  
  useEffect(() => {
    //const controller = new AbortController()
    //const signal = controller.signal


    let isMounted = true
    if (isMounted) {
      fetchPayPeriod()
      .then ((pp) => {
        if (isMounted) {
          store.set('PayPeriod', pp.payPeriod)
          setPayPeriod(pp.payPeriod) //<-- this is generating error... 
          setShifts(pp.shifts)
        }
      })
    } else {
      setIsMounted(true)
      isMounted = true
      fetchPayPeriod()
      .then ((response) => {
        if (isMounted) {
          store.set('PayPeriod', pp.payPeriod)
          setPayPeriod(pp.payPeriod) //<-- this is generating error... 
          setShifts(pp.shifts)
        }
      })
    }

    return () => {
      DEBUG && console.log('useEffect - fetchPayPeriod')
      setIsMounted(false)
      isMounted = false
      //controller.abort();
    }
    
  }, [payPeriodIndex])

  useEffect(() => {
    if (!setIsMounted) setIsMounted(true)

    return () => {
      setIsMounted(false)
    }

  }, [payPeriod])


  
  
  const millisToMinutesAndSeconds = (millis) => {
    var minutes = Math.floor(millis / 60000);
    var seconds = ((millis % 60000) / 1000).toFixed(0);
    return minutes + ":" + (seconds < 10 ? '0' : '') + seconds;
  }

  const onChange = async (event) => {
    DEBUG && console.log('pullHook -> onChange')
    
    //setPullHookState(event.state)
    setTimeout(()=>{
      DEBUG && console.log('delayed for 1 second....')
      setPullHookState('done')
      }, 1000)
      
      await pullRefresh()
  }

  const notPullToRefresh = async (event) => {
    DEBUG && console.log('pullHook -> onChange')
    
    let message = ''
    if (event.state === 'initial') {
      message = 'Pull to refresh'
    } else if (event.state === 'preaction') {
      message = 'Release'
    } else if (event.state === 'action') {
      message = 'Loading...'
    }
      /* {
        (pullHookState === 'initial') ?
          <span >
            <Icon size={35} spin={false} icon='ion-arrow-down-a' />
            Pull down to refresh
          </span> :
          (pullHookState === 'preaction') ?
          <span>
            <Icon size={35} spin={false} icon='ion-arrow-up-a' />
            Release to refresh
          </span>
          :
          (pullHookState === 'action') ?
          <span>
            <Icon size={35} spin={true} icon='ion-load-d'></Icon>
            Loading data...
          </span>
          :<></>
      } */
      return(
        <span>
            {message}
          </span>
      )
   
  }
  const onLoad = (e) => {
    DEBUG && console.log('pullHook -> onLoad')
  }

  return (
    <Page id='ShiftsPage' renderToolbar={renderToolbar} renderFixed={renderShiftActionButton}>
      {isLoading && (
       <div className='approveDivLoading' ><ProgressCircular indeterminate className='approveProgressLoading' /></div>
      )}

      <div className='ShiftsStickyHeader'>
        <div className='PayPeriodNav'>
          <div className='left' onClick={prev}>
            <span>Prev</span>
          </div>
          <div className='center' onClick={setCurrentPayPeriod}> {title} </div>
          <div className='right' onClick={next}>
            <span>Next</span>
          </div>
        </div>
        <div className='ShiftsStickyHeaderRow'>
          <Table {...TOP} className='TableFixedTh'>
            <TableHeader className='xStickyTableHeader'>
              <tr>
                <th>
                  {actions.selected.size !== 0 && (
                    <Button
                      plain
                      size='small'
                      onClick={() => {
                        setActions({...actions, selected: new Set()})
                      }}
                      label='un-select'
                    />
                  )}
                </th>
                <th>Start</th>
                <th>End</th>
                <th>Hrs</th>
                <th
                  onClick={() => {
                    store.clear()
                  }}
                >
                  Status
                </th>
                <th></th>
              </tr>
            </TableHeader>
          </Table>
        </div>
      </div>
    
     
      {/* <SwipeableList> */}
        {/*  <PullHook key={uid(8)} onChange={onChange} onLoad={onLoad}>
        </PullHook>  */}
         <PullToRefresh onRefresh={pullRefresh}>
        <Table {...TOP} className='TableFixedTh'>
          <TableBody scope='row'>
            {/* <SwipeableList> */}
              {shifts.map((shift) => renderRow(shift))}
            {/* </SwipeableList> */}
          </TableBody>
          {/* <List data={ shifts } children={ (S) => renderRows(S) } className="List" /> */}
        </Table>
        </PullToRefresh>
      {/* </SwipeableList> */}

     
      <ActionSheet isOpen={actions.open}>
        <ActionSheetButton className='ActionSheetTitle'>
          <b>{`Bulk Edit ${actions.selected.size} Shifts`}</b>
        </ActionSheetButton>
        {!!canEdit && <ActionSheetButton onClick={() => updateShifts({submit: true})}>Submit Selected Shifts</ActionSheetButton>}
        {!!canEdit && <ActionSheetButton onClick={() => updateShifts({requestedOff: true})}>Request Time Off</ActionSheetButton>}
        {!!canEdit && <ActionSheetButton onClick={() => updateShifts({isSick: true})}>Call in Sick</ActionSheetButton>}

        {!!canApprove && <ActionSheetButton onClick={() => updateShifts({approve: true})}>Approve</ActionSheetButton>}
        {!!canApprove && <ActionSheetButton onClick={() => updateShifts({reject: true})}>Reject</ActionSheetButton>}
        {!!canProcess && <ActionSheetButton onClick={() => updateShifts({process: true})}>Process</ActionSheetButton>}
        {/*{!!canProcess && <ActionSheetButton onClick={() => deleteShifts({process: true})}>Delete</ActionSheetButton>}*/}
        <ActionSheetButton onClick={() => toggleActions()}>Cancel</ActionSheetButton>
      </ActionSheet>
      
      <Toast isOpen={toast.open} animation="fall">
        <div style={{textAlign: 'center'}}>{toast.text}</div>
      </Toast>

      {!!picker.shift && !!picker.open && <TimePicker {...picker} closePicker={closePicker} />}
   
      <SpeedDial disabled={false} direction='left' onClick={handleNewShift} position='right bottom'>
        <Fab>
          <Icon icon='fa-plus' size={26} fixedWidth={false} style={{verticalAlign: 'middle'}} />
        </Fab>
        {/* <SpeedDialItem onClick={handleNewShift}><Icon icon='md-plaster' size={18} /></SpeedDialItem>
        <SpeedDialItem onClick={() => console.log('speed B')}><Icon icon='md-calendar' size={18} /></SpeedDialItem>
        <SpeedDialItem onClick={() => console.log('speed C')}><Icon icon='md-alarm-plus' size={18} /></SpeedDialItem> */}
      </SpeedDial>
   

    </Page>
  )
}

export default Shifts
