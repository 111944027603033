import React, {useState, useEffect} from 'react'
import Picker from 'react-mobile-picker'
import {Box, Button} from 'grommet'
import TimeFormat from 'hh-mm-ss'

import _ from "lodash"

import {store} from '../psrs'
import { duration } from 'moment'


export const SelectHoursMinutesAlt = (props) => {
  const timeEntry = props.timeEntry
  const handleUpdate = props.handleUpdate
  const currentValue = props.timeEntry && props.timeEntry.duration ? props.timeEntry.duration  : 0
  const [hours, setHours] = useState(currentValue)
  const seconds = currentValue * 60 * 60
  const HHMM = TimeFormat.fromS(seconds, 'hh:mm')
  const HM = HHMM.split(':')
  const H = HM[0]
  const M = HM[1]
  const [valueGroups, setValueGroups] = useState({hours: H, minutes: M})

  const optionGroups = {
    hours: ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16'],
    minutes: ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'],
  }

  const handleChange = (name, value) => {
    const valGroup = {...valueGroups}
    valGroup[name] = value
    setValueGroups(valGroup)
  }

  const updateHours = () => {
    const hm = `${valueGroups.hours}:${valueGroups.minutes}`
    const secs = TimeFormat.toS(hm, 'hh:mm')
    const hours = secs / 60 / 60
    //console.log('updateDuration', hours)
    setHours(hours.toPrecision(4))
  }

  useEffect(() => {
    updateHours()
  }, [valueGroups])

  useEffect(() => {
    if (timeEntry) {
      setValueGroups({hours: H, minutes: M})
      setHours(timeEntry ? timeEntry.duration : 0)
    }
  }, [timeEntry])

  return (
    <div className='HourMinutePicker'>
      <Box gap='medium'>
        <Box direction='row' pad='small' fill='horizontal' align='stretch' alignContent='stretch' flex='grow' justify='stretch'>
          <Box pad='small' alignContent='start' align='start'>
            <Button
              secondary
              size="small"
              label='Cancel'
              //fill='horizontal'
              color='#017BFF'
              onClick={() => {
                //props.handleUpdate({field: 'duration', value: timeEntry.duration, id: timeEntry.id})
                props.setDuration({...duration, open: false })
              }}
            />
          </Box>
          <Box fill='horizontal' pad='small'  justify='stretch' />
          <Box pad='small' align='end' alignContent='end'> 
            <Button
              primary
              size="small"
              label='Save'
              //fill='horizontal'
              color='#017BFF'
              onClick={() => {
                props.handleUpdate({field: 'duration', value: hours, id: timeEntry.id})
                props.setDuration({open: false, timeEntry: null})
              }}
            />
          </Box>
        </Box>
        <Box direction='row' pad='xxsmall' fill='horizontal' align='stretch' alignContent='stretch' flex='grow' justify='stretch'>
          <Box flex='grow' pad='xxsmall' size='small' style={{alignItems: 'bottom', color: 'black'}}>
            Hours
          </Box>
          <Box flex='grow' pad='xxsmall' size='small' style={{color: 'black'}}>
            Minutes
          </Box>
        </Box>
        <Box direction='row' pad='xxsmall' fill='horizontal' flex='grow' align='stretch' alignContent='stretch' justify='stretch'>
           <hr width="75%" color="black" />
        </Box>
        <Box pad='xxsmall' background='white'>
          <Picker optionGroups={optionGroups} valueGroups={valueGroups} onChange={handleChange} />
        </Box>
       {/*  <Box pad='small'>
          <Button
            secondary
            label='Cancel'
            fill='horizontal'
            color='#017BFF'
            onClick={() => {
              props.handleUpdate({field: 'duration', value: timeEntry.duration, id: timeEntry.id})
              props.setDuration({open: false, timeEntry: null})
            }}
          />
        </Box>
        <Box pad='small'>
          <Button
            primary
            label='Save'
            fill='horizontal'
            color='#017BFF'
            onClick={() => {
              props.handleUpdate({field: 'duration', value: hours, id: timeEntry.id})
              props.setDuration({open: false, timeEntry: null})
            }}
          />
        </Box> */}
      </Box>
    </div>
  )
}
