import React, {useState, useEffect, createFactory} from 'react'
import {useAbilityContext} from '../useAbilityContext'
import {BackButton, Page, Toolbar, BottomToolbar} from 'react-onsenui'
import {ActionSheet, ActionSheetButton, Icon, Checkbox, Switch, Card, Input, List, ListItem, Col, Row, Toast, Modal} from 'react-onsenui'
import {Button, Box, Text, CheckBox} from 'grommet'
import { Table, TableHeader, TableRow, TableBody, TableCell, Grommet, DataTable, Tip, DropButton} from 'grommet'
import {FormNext} from 'grommet-icons'
import moment from 'moment'
//import TimeFormat from 'hh-mm-ss'
import {TimePicker, StartTimeSelect,  SelectPayType, SelectHoursMinutes, SelectEmployee, SelectSupervisor} from '../components'
import {store, fetch, Can, a, an, This, uid, convertDuration} from '../psrs'
import _ from 'lodash'
import { ScrollMenu, VisibilityContext } from 'react-horizontal-scrolling-menu';

import { SwipeableList, SwipeableListItem, ActionAnimations } from '@sandstreamdev/react-swipeable-list';
import '@sandstreamdev/react-swipeable-list/dist/styles.css';

import { Thead, Tbody, Tr, Th, Td} from 'react-super-responsive-table'
//import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css'

import '../styles/newShift.css'

const DDEBUG = false
const DEBUG = false

export const NewShift = (props) => {
  const {navigator, route} = props
  const {view} = route
  const currentView = store.get('View')
  // console.log("route.data", route.data)
  const ability = useAbilityContext()
  const Employee = route.data && route.data.employee ? route.data.employee : store.get('CurrentUser')
  const viewData = store.get('ViewData')
  const Shifts = viewData ? viewData : []
  const shiftCats = store.get('shiftCategores')
  const Categories = shiftCats ? shiftCats : []
  const _selected = new Set(store.get('ShiftsSelected'))
  const [selected, setSelected] = useState(_selected)
  const [shifts, setShifts] = useState(_.orderBy(Shifts, 'start', 'asc'))
  const [categories, setCategories] = useState(_.orderBy(Categories, 'category', 'asc'))
  const [categorySelected, setCategorySelected] = useState('')
  const [categorySelectedColor, setCategorySelectedColor] = useState('white')
  const [overlayed, setOverlayed] = useState(false)
  const [duration, setDuration] = useState({open: false, timeEntry: null})
  const [toast, setToast] = useState({open: false, text: ''})
  const [useCompPayType, setUseCompPayType] = useState(false)
  const [employeeSelected, setEmployeeSelected] = useState(Employee.number)
  const [supervisorSelected, setSupervisorSelected] = useState(Employee.supervisorNumber)
  const [isMounted, setIsMounted] = useState(false)

  const allClosed = {
    actions: false,
    end: false,
    start: false,
    reasons: false,
    payType: false,
  }
  const [open, setOpen] = useState(allClosed)

  const [newShift, setNewShift] = useState( {
    startDate: moment().format('YYYY-MM-DD'),
    startTime: moment().format('HH:mm'),
    start: moment().format(),
    duration: 0,
    payTypeCode: '',
    employeeNumber: store.get("CurrentUser").number,
    employee: store.get("CurrentUser"),
    userRole: store.get("role"),
    available: false,
    generateTimeEntry: true,
    superNumber1: _.find(store.get("Employees"), { number: store.get("CurrentUser").supervisorNumber }).number,
    supervisor1: _.find(store.get("Employees"), { number: store.get("CurrentUser").supervisorNumber }),
    end: null,
    isValid: false,
})


  // const [overlayed, setOverlayed] = useState(false)

  const [actions, setActions] = useState({
    selected: new Set(),
    open: false,
  })

  const shiftDisabled = false
  const cantEditshift = false
  const canEditshift = true
  
  const toggleOpen = (key) => {
    const O = {
      actions: false,
      endTime: false,
      startTime: false,
      reasons: false,
      payType: false,
      startDate: false,
    }
    if (key) O[key] = !open[key]
    setOpen(O)
  }

  const centerBox = {
    fill: true,
    align: 'center',
    alignContent: 'stretch',
    justify: 'center',
    width: 'xsmall',
    pad: 'none',
    border: true,
  }

  const startBox = {
    align: "start",
    alignContent: 'stretch',
    justify: 'center',
    textAlign: 'start',
    hoverIndicator: true,
    round: 'small',
    pad: 'small',
    wrap: false,
    border: true,
  }

  const pushPage = (S) => {
    store.set('Shift', S)
    navigator.pushPage({
      view: 'Shift',
      data: {shift: S},
      id: S.id,
    })
  }
  

  const fetchCategories = async (signal) => {
    const records = await fetch(`/api/mobile/shift_template_categories/shiftTemplate`,{ signal: signal })
    const ordered = _.orderBy(records, 'category', 'asc')
    store.set('shiftCategories', ordered)
    setCategories(ordered)
  } 
  
  const fetchRecords = async (signal) => {

    const options = {
      signal: signal,
      method: 'POST',
      body: JSON.stringify(
        {
          options: {
            paginate: 1000,
            attributes: ["id","isActive","isOT","category","categoryColor","name","description","payTypeCode","payTypeCodeAlt","canEdit","json"]
          },
          query: {
            and: [
              {
                category: {
                  eq: categorySelected
                }
              },
              {
                isActive: {
                  eq: true
                }
              }
            ]
          }
        }
      )
    }
    try {
      const records = await fetch(`/api/shifttemplate/list`,options)
      const ordered = _.orderBy(records.data.data.data, 'name', 'asc')
      store.set('Shifts', ordered)
      setShifts(ordered)
    
    } catch (e) {
      console.log('fetch error')
    }
    
  }

  const xxconvertDuration = (hours, minutes) => {
    const hm = `${hours}:${minutes}`
    const secs = TimeFormat.toS(hm, 'hh:mm')
    const durationHours = secs / 60 / 60
    DEBUG && console.log('updateDuration', durationHours)
    return durationHours.toPrecision(4)
  }

  const updateShift = async (params) => {
    DEBUG && console.log(`params: ${params}`)

    const updatedTemplate = newShift
    updatedTemplate[params.field] = params.value

    if (params.field === 'start' || params.field === 'startDate' || params.field === 'startTime') {
      updatedTemplate.startDate = moment(params.value).format('YYYY-MM-DD')
      updatedTemplate.startTime = moment(params.value).format('HH:mm')
      updatedTemplate.start = moment(params.value).format()
    }

    
    if (params.field === 'payTypeCode') {
      updatedTemplate.payTypeCode = params.value ? params.payTypeCodeAlt : params.payTypeCode
    }
    
    if (params.field === 'supervisorNumber') {
      updatedTemplate.superNumber1 = params.value
    }

    if (params.id ) {
      const templateIndex = shifts.findIndex(S => S.id === params.id)
      let newValue = params.value
      if (params.field === 'duration') {
        
        //const hhMM = newValue.split('.')

        //const realDuration = convertDuration(hhMM[0],hhMM[1])
        updatedTemplate.duration = parseFloat(newValue)
        //updatedTemplate.duration = parseFloat(realDuration)

      } 

      //shifts[templateIndex].json.shiftJson[params.field] = newValue
    }
    setNewShift(updatedTemplate)
  }

  const createshift = async (props) => {
   
    //console.log("createshift", shift)
    // props.target.id - this is the template id..... 
    // newShift.id is data for shift create.....

    const templateId = props.templateId
    const records = props.templateShift.json
    const newMergedShift = props.mergedShift
    const evidenceShift =  records.shiftJson.category.toLowerCase().includes('evidence') ? true : false

     // check for defuault duration on template update
     const shiftDuration = records.shiftJson
     const validShiftDuration = shiftDuration.timeEntries.filter( t => parseFloat(t.duration) > 0).length > 0 ? true : false

    const validStartDate = newMergedShift ? typeof newMergedShift.startDate  === 'undefined' ? false : true : false
    const validStartTime = evidenceShift ? true : newMergedShift ? typeof newMergedShift.startTime === 'undefined' ? false : true : false
    let validDuration = evidenceShift || validShiftDuration ? true : newMergedShift ? typeof newMergedShift.duration === 'undefined' ? false : true : false
    validDuration = !evidenceShift && newMergedShift.duration === 0 ? false : true

    const existingFields = newMergedShift
    const updates= existingFields ? {...existingFields , validStartTime: validStartTime, validStartDate: validStartDate, validDuration: validDuration} : 
    { validStartTime: validStartTime, validStartDate: validStartDate, validDuration: validDuration}
    const merged = {updates }

    setNewShift({...newMergedShift, ...merged})

   

    //if (newShift[templateId].duration > 24) validDuration = false 

    if (!newMergedShift || !validStartDate || !validStartTime || !validDuration ) {

        const descStartDate = validStartDate ? '' : 'Start Date Required'
        const descStartTime = validStartTime ? '' : 'Start Time Required - must press BLUE OK button'
        const descDuration = validDuration ? '' : 'Duration/Hrs Required or more than allowed'

        setToast({open: true, text: 'Missing Required Fields'})
        setTimeout(() => setToast({...toast, open: false}), 2000)
        
        return false

    } 

    //const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

    const startTime = evidenceShift ? '08:00' : newMergedShift.startTime 
    let localDateTime = `${newMergedShift.startDate}T${startTime}`
    let localDateTimeUTC = moment(localDateTime).utc().format()
    
    newMergedShift.start = localDateTimeUTC

    //shift.start = moment(_shift.start).utc().format("YYYY-MM-DDTHH:mm")
    //shift.end  = moment(_shift.end).utc().format("YYYY-MM-DDTHH:mm")

    const templateData = newMergedShift
    
    // merge with user input with temaplate
    const templateJson = records.shiftJson

    // use alternate pay type from template
    if (useCompPayType[templateId]) {
      templateJson.payTypeCode = templateJson.payTypeCodeAlt ? templateJson.payTypeCodeAlt : templateJson.payTypeCode
    }

    if (templateData && !templateData.employeeNumber) {
      templateData.employeeNumber = newShift.employeeNumber
      templateData.employee = newShift.employee ? newShift.employee : []
    }
    if (templateData && !templateData.superNumber1) {
      templateData.superNumber1 = newShift.superNumber1
      templateData.supervisor1 = newShift.supervisor1 ? newShift.supervisor1 : []
    }


    let mergedShift = {...templateJson, ...templateData}

    // get user data merged
    //mergedShift = {...mergedShift, ...shift}
    //mergedShift = {...shift, ...mergedShift }

    // fix durations
    mergedShift.durationOg = mergedShift.payTypeCode === '250' || mergedShift.payTypeCode === '251'? parseFloat(0).toFixed(2) : parseFloat(mergedShift.duration).toFixed(2)
    mergedShift.durationOt = parseFloat(0).toFixed(2)
    mergedShift.duration = mergedShift.payTypeCode === '250' || mergedShift.payTypeCode === '251'? parseFloat(0).toFixed(2) : parseFloat(mergedShift.duration).toFixed(2)


    // clean up temp id's etc we were using
    delete mergedShift.i
    delete mergedShift.id
    delete mergedShift.startDate
    delete mergedShift.startTime

    //setShift({...shift, ...templateData})

    // check if shift is locked
    const _checkLockDate = moment(mergedShift.start).utc().format('YYYY-MM-DDTHH:MM')
    //const payPeriodLocked = await REQ(`/api/pay_period/locked/${shift.start}`, "GET")
    const payPeriodLocked = await fetch(`/api/pay_period/locked/${_checkLockDate}`, "GET")

    const isLocked = payPeriodLocked.payPeriodLocked ? payPeriodLocked.payPeriodLocked : false


    if (isLocked) {
      // notify user

      setToast({open: true, text: 'Date chosen is in a LOCKED Pay Period'})
      setTimeout(() => setToast({...toast, open: false}), 2000)

      mergedShift.isValid = false
      //-- do ww need all these ?? setState({ ...state, loading: false })

    } else {

         //-- do ww need all these ??  setState({ ...state, loading: true })
    
        if (!mergedShift.end && mergedShift.start) {
          mergedShift.end = moment(mergedShift.start).add(mergedShift.duration, "hours")
          //shift.end = moment(shift.start).tz('utc').add(shift.duration, "hours")
        }

        mergedShift.start = moment(mergedShift.start).utc().format("YYYY-MM-DDTHH:mm")
        mergedShift.end  = moment(mergedShift.end).utc().format("YYYY-MM-DDTHH:mm")

        if (!mergedShift.paidLunch) {
          //shift.duration = parseInt(shift.duration) + .50
          //shift.durationOg = parseInt(shift.duration)
          let resultLunch = parseFloat(mergedShift.duration) + parseFloat('.5');
          mergedShift.duration = parseFloat(resultLunch).toFixed(2)
          mergedShift.durationOg = parseFloat(resultLunch).toFixed(2)
          mergedShift.isOT ? mergedShift.durationOt = parseFloat(resultLunch).toFixed(2) : mergedShift.durationOt = 0      

          mergedShift.paidLunch = false
          mergedShift.end = moment(mergedShift.end).add(.5, "hours").format("YYYY-MM-DD HH:mm")
        }

        // handle assignmnet
        //const assignments = 
        if (mergedShift.assignmentId) {
          const _assignmentId = mergedShift.assignmentId.toLowerCase().replace(/[yY]/g,'i')
          fetch(`/api/search/assignment?search=${_assignmentId}&returning=payTypeCode&returning=name&returning=id&returning=jobStepCode&returning=jobStepTimecardOverride&returning=payTypeCode`, "GET")
          .then(assignments => {
            if (assignments) {
              for (let a of assignments) {
                if ( a.payTypeCode === props.payTypeCode) {
                  // dp we meed tjese ?? setShift({ ...shift, assignmentId: a.id, jobStepCode: a.jobStepCode, jobCode: a.jobCode })
                  mergedShift={...mergedShift, assignmentId: a.id, jobStepCode: a.jobStepCode, jobCode: a.jobCode }
                  //return a.id
                }
              }
            }
      
          })
        }

        // pre-fill resaon code
      const reasons = await fetch("/api/search/reason?search=Evidence&returning=id&returning=description&returning=category&returning=payTypeCode", "GET")
      if (reasons) {
        for (let r of reasons) {
          if ( r.payTypeCode === mergedShift.payTypeCode) {
            // do we need this ?? shift.reasonId = r.id
            mergedShift.reasonId = r.id
             //-- do ww need all these ?? setShift({ ...shift, reasonId: r.id })
          }
        }
      }
   

        // give it a unique name for handling how we created.
        //shift.name = 'NEW SHIFT BUTTON'
        mergedShift.name = `NEW SHIFT BUTTON ${templateId}`
        mergedShift.shiftTemplateId = templateId
        const created = await fetch("/api/shifts/create", {method: 'POST', body: JSON.stringify(mergedShift)})

        DEBUG && console.log('tried to create shift.....')
        if (created) {
          pushPage(created)
          setToast({open: true, text: 'Shift Created'})
        } else {
          setToast({open: true, text: 'Error Creating Shift'})
        }

        setTimeout(() => setToast({...toast, open: false}), 2000)

         //-- do ww need all these ?? setState({ ...state, loading: false })
         //-- do ww need all these ?? created && context.setNewlyCreatedShift(created)
      }
  }

  const closePicker = async () => {
    setOpen(allClosed)
    setOverlayed(false)
  }

  const closePickerAndSave = async (value, field, id, shiftId) => {
    DEBUG && console.log(`shiftId: ${shiftId} | field: ${field} | value: ${value}`)
    updateShift({field, value: value.format(), id})
    setOpen(allClosed)
    setOverlayed(false)
  }

  const timeProps = {closePicker, closePickerAndSave, open: true }

  const openPicker = (field) => {
    if (canEditshift) {
      const open = {...allClosed}
      open[field] = true
      setOverlayed(true)
      setOpen(open)
    }
  }

  const updateShifts = async (params) => {
    const ids = Array.from(actions.selected.values())
    setActions({selected: new Set(), open: false})
    await fetch(`/api/shifts`, {method: 'PATCH', body: {params, ids}})
    await fetchRecords()
    return true
  }


  const xxsm = {size: 'xxsmall'}

  const toggleActions = () => {
    setActions({...actions, open: !actions.open})
  }

  const xTimeBox = ({value, field, shift, disabled, checked}) => {
  
    const P = {
      align: "start",
      alignContent: 'start',
      textAlign: 'start',
      hoverIndicator: true,
      round: 'small',
      pad: 'small',
      wrap: false,
    }

    const TB = {
      start: {...P, background: '#EFEFF4'},
      end: {...P, background: '#EFEFF4'},
      hrs: {...P, background: '#EFEFF4'},
      ot: {...P, background: '#EFEFF4'},
    }

    let TBP = TB[field]

  
    if (checked) {
      TBP = {...TBP, background: 'LightSteelBlue' }
    }
    if (disabled) {
      TBP = {...TBP, background: 'lightgrey' }
    }

    if (disabled !== true && field === 'start') {
      TBP.onClick = () => {
        openPicker(shift, field)
      }
    }
    if (disabled !== true && field === 'end') {
      TBP.onClick = () => {
        openPicker(shift, field)
      }
    }

    return (
      <Box {...TBP}>
        <Text size='xsmall' textAlign='start'>
          {value}
        </Text>
      </Box>
    )
  }


  const TimeBox = ({value, field, disabled, shiftId}) => {
   
    const P = {
      align: "start",
      alignContent: 'stretch',
      justify: 'center',
      textAlign: 'start',
      hoverIndicator: true,
      round: 'small',
      pad: 'small',
      wrap: false,
      border: true,
    }

    const iconBox = {
      align: "center",
      alignContent: 'stretch',
      //justify: 'start',
      textAlign: 'start',
      hoverIndicator: true,
      round: 'small',
      pad: 'xsmall',
      wrap: false,
      border: true,
      flex: "grow",
    }

    const TB = {
      start: {...P, background: 'white'},
      end: {...P, background: 'white'},
      hrs: {...P, background: 'white'},
      ot: {...P, background: 'white'},
    }
    const TBP = TB[field]
    if (canEditshift) {
      if (field === 'start' || field === 'end' ) {
        TBP.onClick = () => {
          DDEBUG && console.log('TBP.onClick', value, field, disabled, cantEditshift)
          if (!shiftDisabled) {
            openPicker(field)
          }
        }
      }
    }

    return (
     
      <Box {...TBP}>
         <Row verticalAlign='center'>
          <Col width={"80%"}>
            <Text tip="Duration" disabled={cantEditshift || shiftDisabled} textAlign='start'>
              {value}
            </Text>
          </Col>
          <Col width={"20%"}>
              <Icon style={{alignContent: "right"}} modifier="material" icon="md-time"/>
          </Col>
      </Row>
       

       
      </Box>
    )
  }

  const TOP = {alignSelf: 'start', margin: 'none'}
  const BT = {border: 'top'}
  const TD = {...BT, size: 'xsmall', pad: 'xsmall'}

  const renderCard = (S) => {

    const category = S.category

    const statusProps = {
      textAlign: 'center',
      // round: "small",
      // pad: "small",
      wrap: false,
      fill: true,
      direction: 'row',
      align: 'center',
    }

    return (
        
          <List key={uid(8)} modifier="inset" style={{width: '100%'}}>
           
            <ListItem key={uid(8)} style={{width: '100%'}}>
              
              {/* <div style={{width: '100%'}}> */}
                {/* <div className='columns'> */}
                <Table key={uid(8)}>
                  <TableBody key={uid(8)}>
                    <TableRow key={uid(8)}>
                      <TableCell key={uid(8)} scope="col" align='start'  className='otStatus'>
                        <div style={{align: 'left'}}>
                          {category}
                        </div>
                    </TableCell>
                    {/* <TableCell key={uid(8)} scope="col" align='end' size="xxsmall" verticalAlign="middle" >
                      <div style={{ align: 'right' }}>
                          <Button style={{size: '100%'}} size='small' onClick={() => pushPage(S)} icon={<FormNext />} />
                      </div>
                    </TableCell> */}
                  </TableRow>
                </TableBody>
              </Table>
                {/* </div>
              </div> */}
            
            </ListItem>
          </List>
       
      
    )
  }

  const RenderCardBootStrap = (S) => {

    const category = S.category

    const statusProps = {
      textAlign: 'center',
      // round: "small",
      // pad: "small",
      wrap: false,
      fill: true,
      direction: 'row',
      align: 'center',
    }

    return (
        
      <div class="card card-body">{category}</div>

    )
  }


  const handleCategoryClick = (itemId) => () => setCategorySelected(itemId)
  const CategoryCard = ({ onClick, selected, category, itemId, maxSize,categoryColor }) => {
    //const visibility = React.useContext(VisibilityContext);
    
    return (
      <div 
        className='shift-card'
        onClick={() => onClick()}
        //style={{
        //  width: `${maxSize}px`,
        //}}
        tabIndex={0}
        style={{ backgroundColor: selected ? "WhiteSmoke" : "white"}}
      >
        <div>
          <div>{category}</div>
          <Box style={{ borderTop: `3px solid ${categoryColor}`}} />
        </div>
      </div>
    )
  }

  const getPayTypes = (p) => {
    const payTypes = store.get("PayTypes")
    let payType = {}
    if (payTypes) {
      payType = payTypes.find(pt => pt.code === p)
    }
    //const payTypeCategory = payType.category.toLowerCase()
    const payTypeDescription = payType && payType.rename ? payType.rename : payType.description
    return payTypeDescription

  }

  const NewShiftCards = (props) => {
    
    const cards = [] 

    const info = (props) => {

        const {title, value } = props.currentTarget
      /*  Modal.info({
          title: `New ${title}  Shift Info`,
          content: (
            <div>
              <p>{value} </p>
            </div>
          ),
          onOk() {},
        })
        */
    }

    shifts.forEach( (t) => { 
      if (t.isActive) {


        // here
      
        const SS = moment(newShift.start)
        const SE = moment(newShift.end)
        const SD = SS.format('ddd MMM Do')
        const ST = SS.format('HH:mm')
        const ET = SE.format('HH:mm')
        const DUR =  t.json.shiftJson.duration ? `${parseFloat(t.json.shiftJson.duration).toFixed(2)}` : parseFloat('0.00')
        const OT =   t.json.shiftJson.duration ? `${parseFloat(t.json.shiftJson.durationOt).toFixed(2)}` :parseFloat('0.00')
        const PAY = getPayTypes(t.json.shiftJson.payTypeCode) 
        /* const evidenceShift = () => {
          let isEvidenceShift = false
          if (t && t.shiftJson.timeEntries) {
            isEvidenceShift = t.category.toLowerCase().includes('evidence')  || ['250','251'].some(e => t.shiftJson.payTypeCode.includes(e)) ? true : false 
          }
          return isEvidenceShift
        } */
      
        /* const durationFromTemplate = () => {
          
          let hasDuration = false

          if (t && t.shiftJson.timeEntries) {
            hasDuration = t.shiftJson.timeEntries.filter( t => parseFloat(parseFloat(t.duration).toFixed(2)) > 0 ).length > 0 ? true : false 
            if (hasDuration && newShift[t.id] && newShift[t.id].validStartDate && newShift[t.id].validStartTime) {
              hasDuration = true
            }
          }
          return hasDuration
        } */

        /* const buttonDisabled = () => {
          if (newShift[t.id]) {
            if (t.category.toLowerCase().includes('evidence')) {
              if (newShift[t.id].validStartDate) {
                return  false
              } else {
                return true
              }
            } else if ((newShift[t.id].validDuration && newShift[t.id].validStartDate && newShift[t.id].validStartTime) 
              || (durationFromTemplate() && newShift[t.id].validStartDate && newShift[t.id].validStartTime)) {
          // } else if ( durationFromTemplate() && newShift[t.id].validStartDate && newShift[t.id].validStartTime) {
                return  false
            } else {
            return true
          } 
          } else {
            return true
          }
        } */
        /* const buttonType = () => {
          if (newShift[t.id]) {
            if (t.category.toLowerCase().toLowerCase().includes('evidence')) {
              if (newShift[t.id].validStartDate) {
                return  'primary'
              } else {
                return 'default'
              }
          } else if ((newShift[t.id].validDuration && newShift[t.id].validStartDate && newShift[t.id].validStartTime) || durationFromTemplate()) {
          // } else if ( durationFromTemplate() && newShift[t.id].validStartDate && newShift[t.id].validStartTime) {
                return  'primary'
            } else {
            return 'default'
          } 
          } else {
            return 'default'
          }
          
        } */
        
        //const useCompPayType = false

        const durationFromTemplate = () => false

        const evidenceShift = () => false

        const fontColor = (parseInt(t.categoryColor.replace('#', ''), 16) > 0xffffff / 2) ? '#000' : '#fff';

        const sd1 = moment(newShift.start).format('YYYY-MM-DD')
        const sdNow = moment().format('YYYY-MM-DD')

        cards.push(
          <div key={`${t.id}-div1`} className='card'>
            <div key={`${t.id}-div2`} className='card-header' style={{color: fontColor, backgroundColor: t.categoryColor}}><strong>{t.name}</strong></div>
            <div key={`${t.id}-div3`} className='card-body'>
              <Row key={`${t.id}-emp`}>
                <Col key={`${t.id}-col1`}>
                  <div className="supervisorSelect flex1">
                    <small><SelectEmployee  disabled = {shiftDisabled} timeEntry={t} update={updateShift} value={newShift.employeeNumber}/></small>
                  </div>
                </Col>
              </Row>
              <br />
              <Row key={`${t.id}-sup`}>
                <Col key={`${t.id}-col2`}>
                  <div key={`${t.id}-div4`} className="supervisorSelect flex1">
                    <small><SelectSupervisor  disabled = {shiftDisabled} timeEntry={t} update={updateShift} value={newShift.superNumber1} /></small>                 
                  </div>
                </Col>
              </Row>
              <br />
              <Row key={`${t.id}-paytype`} >
                <Col key={`${t.id}-col3`} style={{whiteSpace: "nowrap"}}>
                  <strong>Pay Type: </strong><br/>
              
                  <div key={`${t.id}-div5`}>
                    {useCompPayType[t.id] ? t.payTypeCodeAlt : t.payTypeCode} 
                    {" - "}
                    <small>{useCompPayType[t.id] ? getPayTypes(t.payTypeCodeAlt) : getPayTypes(t.payTypeCode)}</small>
                  </div>
                  <div key={`${t.id}-div6`}>
                    <Col key={`${t.id}-col-col1`} style={{whiteSpace: "nowrap"}} >
                      <br />
                      <CheckBox
                        disabled = {!t.payTypeCodeAlt}
                        checked={useCompPayType[t.id] ? useCompPayType[t.id] : false} 
                        label="Comp Time"
                        toggle={true}
                        //material={}
                        onChange={(event)=>{
                          setUseCompPayType({...useCompPayType, [t.id]: event.target.checked })
                          updateShift({id: t.id, field: 'payTypeCode', value: event.target.checked, payTypeCodeAlt:t.payTypeCodeAlt, payTypeCode: t.payTypeCode })
                        }} 
                      />
                    
                        {' '}<small>Comp Time</small>
                        </Col>
                  </div>
                </Col>
              </Row>
              <Row key={`${t.id}-start`}>
                <Col key={`${t.id}-col6`}>
                  <div  key={`${t.id}-div7`} className="dateTimePicker flex1">
                    <br />
                    <label 
                      className={newShift && newShift[t.id] ? newShift[t.id].validStartDate ? 'new-shift-label' : 'new-shift-error-label' :'new-shift-label' }
                    >
                        <strong>Start Date</strong>
                    </label>
                    {/* <Input
                      type='date'
                      value={sd1}
                      //defaultValue={sdNow}
                      onChange={(event) => updateShift({id: t.id, field: 'start', value: event.target.value})}
                      modifier='material'
                    /> */}
                    <Box  {...startBox}>
                      <StartTimeSelect
                        disabled={false}
                        value={sd1}
                        modifier='material'
                        onChange={updateShift}
                        //nChange={(event) => updateShift({id: t.id, field: 'startDate', value: event.target.value})}
                      />
                    </Box>
                    

                  </div>
                </Col> 
                <Col key={`${t.id}-col5`}>
                  <div key={`${t.id}-div8`}>
                    <br />
                    <label 
                      className={ t.category && t.category.toLowerCase().includes('evidence') ? 'new-shift-evidence' : newShift && newShift[t.id] ? newShift[t.id].validStartTime ? 'new-shift-label' : 'new-shift-error-label' : 'new-shift-label'}
                    >
                      <strong>Start Time</strong>
                    </label>
                    <TimeBox disabled={cantEditshift || shiftDisabled} shiftId={t.id} value={ST} field='start' />
                    
                  </div>
                </Col>
              </Row>
              <Row key={`${t.id}-duration`}>
                <Col key={`${t.id}-col8`}>
                  <Box  {...{...startBox,border: false}}>
                      <div  key={`${t.id}-div9`} className="durationField flex1" ><label className={durationFromTemplate() || evidenceShift() ? 'new-shift-label' :  newShift[t.id] ? newShift[t.id].validDuration ? 'new-shift-label' : 'new-shift-error-label' : 'new-shift-label' }><strong>Duration</strong></label>
                      </div>
                  </Box>
          
                   <Duration disabled={shiftDisabled} timeEntry={t} value={newShift.duration} />
                   {/* <input 
                     disabled={shiftDisabled}
                     type="number" 
                     inputMode="decimal"
                     //defaultValue={newShift.duration}
                     onChange={updateShift}
                     /> */}
                   

                    {/*<SelectHoursMinutes disabled={shiftDisabled } timeEntry={t} handleUpdate={updateShift} />*/}

                    
                </Col>
              </Row>
            </div>
            <div key={`${t.id}-div10`} className='card-footer'>
              <a href="#" className='btn btn-primary' onClick={()=> { 
                DEBUG && console.log(newShift)
                DEBUG && console.log(useCompPayType)

                const pCode = useCompPayType ? t.payTypeCodeAlt : t.payTypeCode

                const templateShift = shifts.find(ts => ts.id === t.id)
                const mergedShift = ({...templateShift.json.shiftJson, ...newShift, payTypeCode: pCode})

                DEBUG && console.log(mergedShift)

                createshift({templateId: t.id, templateShift, mergedShift})
                
              }}>Create</a>
            </div>
          </div>
        )
      }
    })

    return cards
  }

  const renderBack = true //navigator.routes.length > 2

  //const count = actions.selected.size
  const viewTitle = view === 'Approvals' ? 'Approvals' : `${Employee.fullName} Shifts`
  //const buttonTitle = view === 'Approvals' ? `Approve / Reject ${count} Shifts` : `Edit ${count} Selected Shifts`
  //const buttonPlaceholder = view === 'Approvals' ? 'Select Shifts to Approve / Reject' : 'Select Shifts to Edit'

  const renderToolbar = () => (
    <Toolbar>
      {!!renderBack && (
        <div className='left'>
          <BackButton>Back</BackButton>
        </div>
      )}
      <div className='center'> {viewTitle} </div>
    </Toolbar>
  )


  const xDuration = ({timeEntry, value}) => {
    DEBUG && console.log('Duration', timeEntry)

    let checkDuration = 0.00
    let allowChange = true

    const hrsBox = {
      align: "end",
      alignContent: 'stretch',
      //justify: 'start',
      textAlign: 'start',
      hoverIndicator: true,
      round: 'small',
      pad: 'xsmall',
      wrap: false,
      border: true,
      style: {backgroundColor: 'lightGrey'}
    }
    
    if (timeEntry.json.shiftJson.hasOwnProperty('duration')) {
      if (parseFloat(timeEntry.json.shiftJson.duration).toFixed(2) > 0) {
        checkDuration = parseFloat(timeEntry.json.shiftJson.duration).toFixed(2)
        allowChange = false
      } else {
        checkDuration = parseFloat(value).toFixed(2)
      }
    }
     
    //{timeEntry.json.shiftJson.hasOwnProperty('duration') ? parseFloat(timeEntry.json.shiftJson.duration).toFixed(2) : 0.00}

    /* return (
      <Box {...startBox}>
        <Box
          disabled = {shiftDisabled || !allowChange}
          background='green'
          pad='medium'
          onClick={() => {
            if (!shiftDisabled) {
              setDuration({timeEntry, open: true})
            }
          }}
        >
               {checkDuration} 
        </Box>
      </Box>
    ) */

    return (
    
       <Box {...startBox}>
         <Row key={`${timeEntry.id}-row1`}>
         <Col key={`${timeEntry.id}-col9`}
          >
            <Text
            text size='medium' 
            textAlign='start'           
            onClick={() => {
            if (!shiftDisabled) {
              setDuration({timeEntry, open: true})
            }
          }}>
            {checkDuration} 
          </Text>

          </Col>
          <Col width={40} key={'thekeyagain2'} align="end">
            <Box {...hrsBox}>
            <strong>Hrs</strong>
      
           </Box>
          </Col>
          </Row>
          
      </Box>
    )
  }

  const Duration = ({timeEntry, value}) => {
    DEBUG && console.log('Duration', timeEntry)

    let checkDuration = 0.00
    let allowChange = true

    const hrsBox = {
      align: "end",
      alignContent: 'stretch',
      //justify: 'start',
      textAlign: 'start',
      hoverIndicator: true,
      round: 'small',
      pad: 'xsmall',
      wrap: false,
      border: true,
      style: {backgroundColor: 'lightGrey'}
    }
    
    if (timeEntry.json.shiftJson.hasOwnProperty('duration')) {
      if (parseFloat(timeEntry.json.shiftJson.duration).toFixed(2) > 0) {
        checkDuration = parseFloat(timeEntry.json.shiftJson.duration).toFixed(2)
        allowChange = false
      } else {
        checkDuration = parseFloat(value).toFixed(2)
      }
    }

    const tt = {...timeEntry, duration: checkDuration }
//{checkDuration}
    return (
    
    
       <Box {...startBox}>
         <Row key={`${timeEntry.id}-row1`}>
          <Col key={`${timeEntry.id}-col9`}>        
              <SelectHoursMinutes disabled={shiftDisabled } timeEntry={tt} handleUpdate={updateShift} />
          </Col>
          <Col width={40} key={'thekeyagain2'} align="end">
            <Box {...hrsBox}>
              <strong>Hrs</strong>
           </Box> 
          </Col>
          </Row>
          
      </Box>
    )
  }

  const canEdit = ability.can('edit', an('Employee', Employee))
  //const canApprove = ability.can('approve', an('Employee', Employee))
  const canApprove = true //ability.can('approve', an('Shift', Shifts))

  const canProcess = ability.can('process')

  const maxSize = Math.max(...(categories.map(el => el.category.length)))


  const renderApprovalActionButton = () => {
    const disabled = count === 0
    return (
      <Can I='edit:shifts' an={Employee}>
        <BottomToolbar>
          <Box justify='center' align='center' fill direction='row' pad='none'>
            <Button disabled={disabled} onClick={toggleActions} label={disabled ? buttonPlaceholder : buttonTitle} primary fill />
          </Box>
        </BottomToolbar>
      </Can>
    )
  }

  useEffect(() => {
    const controller = new AbortController()

    if (isMounted) {
      store.set('Shifts', shifts)
    } else {
      setIsMounted(true)
      store.set('Shifts', shifts)
    }

    return () => {
      setIsMounted(false)
      controller.abort();

    }

  }, [shifts])

  useEffect(() => {
    const controller = new AbortController()

    if (isMounted) {
      fetchCategories()
      //fetchRecords()
    } else {
      setIsMounted(true)
      fetchCategories()
      //fetchRecords()
    }

    return() => { 
      setIsMounted(false)
      store.remove("Shifts")
      controller.abort()
    }

  }, [])

  useEffect(() => {
    //fetchCategories()
    fetchRecords()
  }, [categorySelected])


 /*  useEffect(() => {
    DEBUG && ('template Updated')
  }, [newShift])
 */
   useEffect(() => {
//     console.log('template Updated')
     setUseCompPayType(useCompPayType)
 }, [useCompPayType])

//  //{categories.map(({category},{categoryColor}) => (
  if (categories.length > 0) {
    //const test1 = categories.map(({category}))
    //const test2 = categories.map(({category},{categoryColor}))
    //const test3 = categories.map(({category},{categoryColor},{categoryId}))
  }

  const cats = []
  const cardCategories = categories.map( c => ({category: c.category, categoryColor: c.categoryColor}))
  cardCategories.forEach(({category, categoryColor}, i) => cats.push(
    <CategoryCard
      maxSize={maxSize}
      itemId={category} // NOTE: itemId is required for track items
      category={category}
      key={category}
      selectedColor={categoryColor}
      categoryColor={categoryColor}
      onClick={handleCategoryClick(category)}
      selected={category === categorySelected}
    />
  ))
      
  useEffect(() => {
    const controller = new AbortController()

    if (isMounted) {
      store.set('Shifts', shifts)
    } else {
      setIsMounted(true)
      store.set('Shifts', shifts)
    }

    return () => {
      setIsMounted(false)
      controller.abort();

    }

  }, [newShift, newShift.duration])


  return (
    <Page id='ApprovalsPage' renderToolbar={renderToolbar}>
      <Box full >
           
                {/* <RenderCardBootStrap 
                   category = {categories.map((category) => renderCard(category))}>
                </RenderCardBootStrap> */}
                  <ScrollMenu 
                    
                  >
                  
                   {/*{categories.map(({category, categoryColor}) => (*/}
                   {/* {cardCategories.forEach(({category, categoryColor}, index) => {
                      return (<CategoryCard
                        maxSize={maxSize}
                        itemId={category} // NOTE: itemId is required for track items
                        category={category}
                        key={category}
                        selectedColor={categoryColor}
                        onClick={handleCategoryClick(category)}
                        selected={category === categorySelected}
                      />)
                      })} */}
                      {cats}
                  </ScrollMenu>
      </Box>
      <Box full>
           <NewShiftCards key='xxxxxxx' group={categorySelected} />
      </Box>

      {/* CREATE INDICATOR */}
      <Toast isOpen={toast.open} animation="fall">
        <div style={{textAlign: 'center'}}>{toast.text}</div>
      </Toast>

      {open.start && <TimePicker shift={newShift} field='start' {...timeProps} />}

{/*
      <Modal isOpen={duration.open}>
        <SelectHoursMinutes disabled = {shiftDisabled} timeEntry={duration.timeEntry} handleUpdate={updateShift} setDuration={setDuration} />
      </Modal>
*/}
    </Page>
  )
}

export default NewShift
